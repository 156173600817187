import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import GalleryButton from "../components/gallery_button";
import Gid from "../components/gid";
import Viewing from "../components/viewing";

// Patterns
import PatternFirst from "../images/pattern_01.png";
import PatternSecond from "../images/pattern_02.png";
import PatternThird from "../images/pattern_03.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import Layout from "../components/layout";
import SEO from "../components/seo";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

export default function About({
  data: { page, life, contacts, forms, header, footer, socials, copyright, user, cookies },
}) {
  const wpContent = page.nodes[0];
  forms = forms.nodes[0]
  contacts = contacts.nodes[0]

  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={forms}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={page.nodes[0].locale.locale}
      uri={page.nodes[0].uri}
    >
      <SEO title={wpContent.seo.title} description={wpContent.seo.metaDesc} />
      <header className="head">
        <nav className="breadcrumb" aria-label="Breadcrumb">
          <ol className="breadcrumb__list">
            {wpContent.seo.breadcrumbs.map((value, i) => (
              <li key={i} className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to={value.url}
                  dangerouslySetInnerHTML={{
                    __html: value.text,
                  }}
                ></Link>
              </li>
            ))}
          </ol>
        </nav>
        <h1>{wpContent.pageAbout.pageabouttitle}</h1>
        <div className="pattern">
          <img src={PatternFirst} alt="Pattern" />
        </div>
      </header>
      <div id="mainSwiper" className="mainSwiper">
        <GalleryButton text="Наступний" />
        <Swiper
          navigation={{
            nextEl: "#mainSwiper .swiperButton",
          }}
          loop={true}
          speed={800}
          spaceBetween={20}
          slidesPerView={"auto"}
          breakpoints={{
            "1300": {
              "spaceBetween": 48,
            },
            "768": {
              "spaceBetween": 30,
            }
          }}
        >
          {wpContent.pageAbout.pageaboutmainslider.map((value, i) => (
            <SwiperSlide key={i}>
              <GatsbyImage
                image={value.localFile.childImageSharp.gatsbyImageData}
                alt={value.localFile.childImageSharp.altText || ``}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <h2>{wpContent.pageAbout.pageaboutlocationtitle}</h2>
      <div
          dangerouslySetInnerHTML={{
            __html: wpContent.pageAbout.pageaboutlocationdesc,
          }}
        ></div>
      <div className="aboutIcons">
        {wpContent.pageAbout.pageaboutlocationadvantages.map((value, i) => (
          <div key={i} className="aboutIcons-item">
            <div
              className="icon"
              dangerouslySetInnerHTML={{
                __html: value.icon,
              }}
            ></div>
            <span
              dangerouslySetInnerHTML={{
                __html: value.icontext,
              }}
            ></span>
          </div>
        ))}
      </div>
      <div className="patternBox">
        <div className="pattern topPos mobileSmall">
          <img src={PatternSecond} alt="Pattern" />
        </div>
        <h2>{wpContent.pageAbout.pageaboutlifeadvantagestitle}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: wpContent.pageAbout.pageaboutlifeadvantagesdesc,
          }}
        ></div>
      </div>
      <Gid content={life.nodes[0].blockLifeAdvantages} />
      <div className="patternBox reverseStyle">
        <div className="pattern mobileSmall">
          <img src={PatternThird} alt="Pattern" />
        </div>
        <h2>{wpContent.pageAbout.ownhousetitle}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: wpContent.pageAbout.ownhousedesc,
          }}
        ></div>
        <ul className="iconsLine">
          {wpContent.pageAbout.ownhouseadvantages.map((value, i) => (
            <li key={i} className="iconsLine-item">
              <div
                className="iconsLine-icon"
                dangerouslySetInnerHTML={{
                  __html: value.icon,
                }}
              ></div>
              <div className="iconsLine-desc">{value.icontext}</div>
            </li>
          ))}
        </ul>
        <div className="buttonBox">
          <Link
            className="button minWidth"
            to={wpContent.pageAbout.ownhousebuttonlink}
          >
            {wpContent.pageAbout.ownhousebuttontext}
          </Link>
        </div>
      </div>
      <Viewing forms={forms} contacts={contacts} />
    </Layout>
  );
}

export const query = graphql`
  {
    copyright: allWpRepeatableBlocks(
      filter: {slug: {eq: "copyrights"}}
    ) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(filter: {slug: {eq: "ugoda-korystuvacha"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(filter: {slug: {eq: "polityka-cookies"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    page: allWpPage(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "about" } }
    ) {
      nodes {
        uri
        slug
        seo {
          title
          metaDesc
          breadcrumbs {
            text
            url
          }
        }
        locale {
          locale
        }
        title
        pageAbout {
          ownhousebuttonlink
          ownhousebuttontext
          ownhousedesc
          ownhousetitle
          pageaboutlifeadvantagesdesc
          pageaboutlifeadvantagestitle
          pageaboutlocationdesc
          pageaboutlocationtitle
          pageabouttitle
          ownhouseadvantages {
            icon
            icontext
          }
          pageaboutlifeadvantages {
            icon
            icontext
          }
          pageaboutlifemap {
            buttontext
            buttonlink
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          pageaboutlifeslider {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          pageaboutlocationadvantages {
            icon
            icontext
          }
          pageaboutmainslider {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
    life: allWpRepeatableBlocks(
      filter: {
        slug: { eq: "blok-perevagy-zhyttya" }
        locale: { locale: { eq: "uk" } }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        blockLifeAdvantages {
          advantagesMap {
            buttonLink
            buttonText
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          advantagesSlider {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          advantagesTimeline {
            icon
            text
          }
          advantagesSliderNext
          advantagesSliderPrev
        }
      }
    }
    contacts: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "kontaktna-informacziya" }
      }
    ) {
      nodes {
        slug
        title
        blockContacts {
          contactAddressText
          contactAddressTitle
          contactEmailAdressText
          contactEmailAdressTitle
          contactPhonesListTitle
          fieldGroupName
          scheduleListTitle
          contactPhonesList {
            phoneNumber
          }
          scheduleList {
            item
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "formy" } }
    ) {
      nodes {
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          invalidMailMsg
          invalidPhoneMsg
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
